#SampleSubmissionContainer {
	font-size: 16px;
	line-height: 24px;
	margin: 32px;
	padding: 0;
}


#SampleSubmissionLogo {
	width: 300px;
}

h1 {
	font-size: 32px;
	font-weight: normal;
	line-height: 48px;
	margin: 0;
	text-align: center;
}

h2 {
	color: #FFF;
	font-size: 24px;
	font-weight: bold;
	line-height: 56px;
	margin: 0;
	padding: 0;
}

li,
ol {
	margin: 8px 0 0;
}

p {
	margin: 16px 0 0;
}

.sample-submission-action {
	align-items: center;
	display: flex;
	justify-content: center;
	
	button {
		margin: 16px 8px;
	}
}

.sample-submission-agreement {
	display: none;
}

.sample-submission-qr-code {
	display: none;
}

.section-header {
	align-items: center;
	background: #6699CC;
	color: #FFF;
	display: flex;
	justify-content: space-between;
	margin: 16px 0 0;
	padding: 0 14px;

	button {
        color: #FFF;
        border-color: #FFF;
    }
}

@media print {

	#SampleSubmissionContainer {
		font-size: 0.15in;
		line-height: 0.225in;
		margin: 0;
	}

	#SampleSubmissionLogo {
		width: 3in;
	}

	h1 {
		font-size: 0.3in;
		line-height: 0.45in;
	}

	h2 {
		font-size: 0.15in;
		line-height: 0.225in;
		margin: 0;
		padding: 0;
	}

	li,
	ol {
		margin: 0.075in 0 0;
	}

	p {
		margin: 0.15in 0 0;
	}

	.sample-submission-action {
		display: none;
	}

	.sample-submission-agreement {
		display: block;
	}

	.sample-submission-qr-code {
		display: block;
	}

	.section-header {
		margin: 0.1in 0 0;
		padding: 0 0.075in;
	}
}